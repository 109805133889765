<template>
  <Card class="mt-20" data-menu-bg="true">
    <div class="pt-20 container bg-white d-flex flex-column align-center">
      <h1 class="h1 text-secondary w-100 text-center">{{ strapi.Title }}</h1>
      <VImg v-if="strapi.Image" class="rounded-lg mh-560px" width="1060" cover :src="strapi.Image.url" :alt="strapi.Image.alternativeText" />
      <div v-markdown="strapi.Introduction" class="pt-20 pb-20 paragraph-large mw-720px richText" />
    </div>
    <PageBuilder v-if="strapi.Modules" :modules="strapi.Modules" />
  </Card>
</template>

<script>
export default {
  name: 'CustomerReferenceShow',
  data() {
    return {
      loading: false,
      ...this.$controller.data
    };
  }
};
</script>

<style lang="scss" scoped></style>
