// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.image-container[data-v-2b3f21ac] {
  aspect-ratio: 2.54 / 1;
}
`, "",{"version":3,"sources":["webpack://./app/js/vue/views/blog/components/BlogHero.vue"],"names":[],"mappings":";AAsCA;EACE,sBAAsB;AACxB","sourcesContent":["<script setup>\nimport AuthorCard from '@/components/AuthorCard.vue';\nimport BlogShareButtons from './BlogShareButtons.vue';\n\nconst props = defineProps({\n  strapi: {\n    type: Object,\n    required: true,\n    default: () => {}\n  },\n  authors: {\n    type: Array,\n    required: true,\n    default: () => []\n  }\n});\n</script>\n\n<template>\n  <div class=\"container grid pt-15\">\n    <div class=\"grid-item -col-span-12 -col-span-md-10 -col-start-md-2 -col-span-lg-8 -col-start-lg-3 -col-span-xl-6 -col-start-xl-4\">\n      <VImg v-if=\"props.strapi?.Image\" class=\"rounded-lg w-100 mt-6 mx-auto\" :aspect-ratio=\"2.54\" :src=\"props.strapi?.Image.url\" :alt=\"props.strapi?.Image.alternativeText\" />\n      <div class=\"w-100 mx-auto\">\n        <h1 class=\"text-secondary mt-6 -no-margin\">{{ props.strapi?.Title }}</h1>\n        <span v-if=\"props.strapi?.PublicationDate\" class=\"paragraph-large -no-margin mt-n2 text-grey max-w-1060px\">{{ $utils.formatDateAsDayMonthYear(props.strapi?.PublicationDate) }}</span>\n        <div class=\"d-flex flex-wrap ga-4 ga-md-8 mt-6\">\n          <div v-for=\"author in props.authors\" :key=\"author.id\">\n            <AuthorCard :author=\"author.attributes\" />\n          </div>\n        </div>\n        <BlogShareButtons v-if=\"props.strapi?.ShareButtons\" class=\"mt-6\" :strapi=\"props.strapi.ShareButtons\" :title=\"props.strapi?.Title\" />\n        <p v-if=\"props.strapi?.Type\" class=\"type-tag\" :class=\"{ 'text-accent technicalBg': props.strapi?.Type === 'Technical', 'text-primary generalBg': props.strapi?.Type === 'General', 'text-secondary productBg': props.strapi?.Type === 'Product' }\">{{ props.strapi?.Type }}</p>\n      </div>\n    </div>\n  </div>\n</template>\n\n<style scoped>\n.image-container {\n  aspect-ratio: 2.54 / 1;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
